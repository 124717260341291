import React, { FC, useEffect } from "react";
import qs from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import Section from "../../components/section";
import "./styles.scss";

const DashRedirectForAdmin: FC<{}> = ({}) => {
  useEffect(() => {
    const queryData = qs.parse(global.window.location.search);
    if (queryData && queryData["token"] && queryData["user_id"]) {
      const newObj = JSON.stringify({
        token: queryData["token"],
        user_id:
          queryData["user_id"] &&
          typeof queryData["user_id"] === "string" &&
          parseInt(queryData["user_id"]),
        productType: 'hexomatic',
      });
      const inputUrl = 
      window.location.host.includes("37.186.119.181")
        ? "http://37.186.119.181:4000/api/app/super_user_auth_LXbkXE4TGn" :
      window.location.host.includes("localhost") ?
      "http://localhost:4000/api/app/super_user_auth_LXbkXE4TGn"
        : "https://api.hexomatic.com/v2/app/super_user_auth_LXbkXE4TGn";

      fetch(inputUrl, {
        redirect: "follow",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: "POST",
        body: newObj,
      })
        .then(() => {
          window.location.href = window.location.host.includes("37.186.119.181")
            ? "http://37.186.119.181:3279"
            : "https://dash.hexomatic.com";
        })
        .catch(({ error }) => {
          console.log("redirect to user-dash error", error);
        });
    }
  }, []);

  return (
    <div className="dash-redirect-page">
      <Layout>
        <PageHero title="Hexomatic" titleSmall />
        <Section>
          <p className="text-center w-100">
            You will be redirected to dashboard in a minute
            <FontAwesomeIcon icon={faSpinner} className="button-loading ml-2" />
          </p>
        </Section>
      </Layout>
    </div>
  );
};

export default DashRedirectForAdmin;
